// Usage: testSupport({client?: string, os?: string}[])
// Client and os are regular expressions.
// See: https://cdn.jsdelivr.net/npm/device-detector-js@2.2.10/README.md for
// legal values for client and os
import { ControlPanel, FPS, InputImage, Rectangle, Slider, SourcePicker, StaticText, Toggle } from "@mediapipe/control_utils";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { Options, POSE_CONNECTIONS, POSE_LANDMARKS_LEFT, POSE_LANDMARKS_NEUTRAL, POSE_LANDMARKS_RIGHT, Results } from "@mediapipe/holistic";
import { Pose } from "@mediapipe/pose";
import { useEffect, useRef, useState } from "react";
import { rowerJoints$ } from "../domain/CameraAngels";


//const controls = window;
const options = {
  locateFile: (file: string) => {
    //pose@${VERSION}
    return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
  },
};



function useMediaPipe() {

  // // Our input frames will come from here.
  // const videoElement = document.getElementsByClassName(
  //   'input_video'
  // )[0] as HTMLVideoElement;
  // const canvasElement = document.getElementsByClassName(
  //   'output_canvas'
  // )[0] as HTMLCanvasElement;
  // const controlsElement = document.getElementsByClassName(
  //   'control-panel'
  // )[0] as HTMLDivElement;
  // const canvasCtx = canvasElement.getContext('2d')!;
  const webcamRef = useRef(null)
  const canvasRef = useRef(null)
  const spinnerRef = useRef(null)
  const controlPanelRef = useRef(null)

  const [didInit, setDidInit] = useState(false);

  const refs = [webcamRef, canvasRef, spinnerRef, controlPanelRef]
  useEffect(() => {

    if ([webcamRef, canvasRef, spinnerRef, controlPanelRef].some(x => x.current == null) || didInit) {
      return;
    }

    setDidInit(true)

    const videoElement = webcamRef.current as any as HTMLVideoElement;
    const canvasElement = canvasRef.current as any as HTMLCanvasElement
    const controlsElement = controlPanelRef.current as any as HTMLDivElement
    const canvasCtx = canvasElement.getContext('2d')!;


    // We'll add this to our control panel later, but we'll save it here so we can
    // call tick() each time the graph runs.
    const fpsControl = new FPS();

    // Optimization: Turn off animated spinner after its hiding animation is done.
    const spinner = document.querySelector('.loading')! as HTMLDivElement;
    spinner.ontransitionend = () => {
      spinner.style.display = 'none';
    };

    // const landmarkContainer = document.getElementsByClassName(
    //   'landmark-grid-container'
    // )[0] as HTMLDivElement;
    // const grid = new LandmarkGrid(landmarkContainer, {
    //   connectionColor: 0xcccccc,
    //   definedColors: [
    //     { name: 'LEFT', value: 0xffa500 },
    //     { name: 'RIGHT', value: 0x00ffff },
    //   ],
    //   range: 2,
    //   fitToGrid: true,
    //   labelSuffix: 'm',
    //   landmarkSize: 2,
    //   numCellsPerAxis: 4,
    //   showHidden: false,
    //   centered: true,
    // });

    let activeEffect = 'mask';
    function onResults(results: Results): void {
      // Hide the spinner.
      document.body.classList.add('loaded');

      if (results.poseLandmarks?.length) {



        rowerJoints$.next(results.poseLandmarks)
      }

      // Update the frame rate.
      fpsControl.tick();
      // Draw the overlays.
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

      if (results.segmentationMask) {
        canvasCtx.drawImage(
          results.segmentationMask,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );

        // Only overwrite existing pixels.
        if (activeEffect === 'mask' || activeEffect === 'both') {
          canvasCtx.globalCompositeOperation = 'source-in';
          // This can be a color or a texture or whatever...
          canvasCtx.fillStyle = '#00FF007F';
          canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
        } else {
          canvasCtx.globalCompositeOperation = 'source-out';
          canvasCtx.fillStyle = '#0000FF7F';
          canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
        }

        // Only overwrite missing pixels.
        canvasCtx.globalCompositeOperation = 'destination-atop';
        canvasCtx.drawImage(
          results.image,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );

        canvasCtx.globalCompositeOperation = 'source-over';
      } else {
        canvasCtx.drawImage(
          results.image,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
      }

      if (results.poseLandmarks) {
        drawConnectors(
          canvasCtx,
          results.poseLandmarks,
          POSE_CONNECTIONS,
          { visibilityMin: 0.65, color: 'white' }
        );
        drawLandmarks(
          canvasCtx,
          Object.values(POSE_LANDMARKS_LEFT).map(
            (index) => results.poseLandmarks[index]
          ),
          { visibilityMin: 0.65, color: 'white', fillColor: 'rgb(255,138,0)' }
        );
        drawLandmarks(
          canvasCtx,
          Object.values(POSE_LANDMARKS_RIGHT).map(
            (index) => results.poseLandmarks[index]
          ),
          { visibilityMin: 0.65, color: 'white', fillColor: 'rgb(0,217,231)' }
        );
        drawLandmarks(
          canvasCtx,
          Object.values(POSE_LANDMARKS_NEUTRAL).map(
            (index) => results.poseLandmarks[index]
          ),
          { visibilityMin: 0.65, color: 'white', fillColor: 'white' }
        );
      }
      canvasCtx.restore();

      // //@ts-ignore
      // if (results.poseWorldLandmarks) {
      //   //@ts-ignore
      //   grid.updateLandmarks(results.poseWorldLandmarks, POSE_CONNECTIONS, [
      //     { list: Object.values(POSE_LANDMARKS_LEFT), color: 'LEFT' },
      //     { list: Object.values(POSE_LANDMARKS_RIGHT), color: 'RIGHT' },
      //   ]);
      // } else {
      //   grid.updateLandmarks([]);
      // }
    }

    const pose = new Pose(options);
    pose.onResults(onResults as any);

    // Present a control panel through which the user can manipulate the solution
    // options.
    new ControlPanel(controlsElement, {
      selfieMode: true,
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: false,
      smoothSegmentation: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
      effect: 'background',
    })
      .add([
        new StaticText({ title: 'MediaPipe Pose' }),
        fpsControl,
        new Toggle({ title: 'Selfie Mode', field: 'selfieMode' }),
        new SourcePicker({
          onSourceChanged: () => {
            // Resets because this model gives better results when reset between
            // source changes.
            pose.reset();
          },
          onFrame: async (input: InputImage, size: Rectangle) => {
            const aspect = size.height / size.width;
            let width: number, height: number;
            if (window.innerWidth > window.innerHeight) {
              height = window.innerHeight;
              width = height / aspect;
            } else {
              width = window.innerWidth;
              height = width * aspect;
            }
            canvasElement.width = width;
            canvasElement.height = height;
            await pose.send({ image: input });
          },
        }),
        new Slider({
          title: 'Model Complexity',
          field: 'modelComplexity',
          discrete: ['Lite', 'Full', 'Heavy'],
        }),
        new Toggle({
          title: 'Smooth Landmarks',
          field: 'smoothLandmarks',
        }),
        new Toggle({
          title: 'Enable Segmentation',
          field: 'enableSegmentation',
        }),
        new Toggle({
          title: 'Smooth Segmentation',
          field: 'smoothSegmentation',
        }),
        new Slider({
          title: 'Min Detection Confidence',
          field: 'minDetectionConfidence',
          range: [0, 1],
          step: 0.01,
        }),
        new Slider({
          title: 'Min Tracking Confidence',
          field: 'minTrackingConfidence',
          range: [0, 1],
          step: 0.01,
        }),
        new Slider({
          title: 'Effect',
          field: 'effect',
          discrete: { background: 'Background', mask: 'Foreground' },
        }),
      ])
      .on((x) => {
        const options = x as Options;
        videoElement.classList.toggle('selfie', options.selfieMode);
        activeEffect = (x as { [key: string]: string })['effect'];
        pose.setOptions(options);
      });


  }, [didInit, setDidInit, webcamRef, canvasRef, spinnerRef, controlPanelRef]);
  return refs;
}

export default useMediaPipe;