import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { angels$ } from './domain/CameraAngels';
import useMediaPipe from './hooks/demoHook';
import './index.css';

export function Demo() {
  const [webcam, canvas, spinner, controlPanel] = useMediaPipe();

  const value = useObservable(() => angels$);
  return (
    <div>
      <div>{JSON.stringify(value)}</div>
      <div className="container">
        <video className="input_video" ref={webcam}></video>
        <canvas
          className="output_canvas"
          width="1280px"
          height="720px"
          ref={canvas}
        ></canvas>
        <div className="loading" ref={spinner}>
          <div className="spinner"></div>
          <div className="message">Loading</div>
        </div>
      </div>
      <div className="control-panel" ref={controlPanel}></div>
      <div className="square-box">
        <div className="landmark-grid-container"></div>
      </div>
    </div>
  );
}
