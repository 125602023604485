import { NormalizedLandmark, NormalizedLandmarkList, POSE_LANDMARKS_LEFT, POSE_LANDMARKS_RIGHT } from '@mediapipe/holistic';
import { BodyAngels } from '../models/angels';

function vectorNorm(x: number, y: number, z: number) {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
}

export function AngleBetweenJoints([A, B, C]: NormalizedLandmark[]): number | null {

  const XlengteAB = B.x - A.x;
  const YlengteAB = B.y - A.y;
  const ZlengteAB = B.z - A.z;
  const VectorAB = vectorNorm(XlengteAB, YlengteAB, ZlengteAB);
  const XlengteAC = C.x - A.x;
  const YlengteAC = C.y - A.y;
  const ZlengteAC = C.z - A.z;
  const VectorAC = vectorNorm(XlengteAC, YlengteAC, ZlengteAC);
  const ABAC = XlengteAB * XlengteAC + YlengteAB * YlengteAC + ZlengteAB * ZlengteAC;
  const x = ABAC / (VectorAB * VectorAC);
  if (!isNaN(x)) {
    if (-1 <= x && x <= 1) {
      const angleRad = Math.acos(x);
      return Math.round(angleRad * (180.0 / Math.PI));
    }
    else
      return null;


  }
  return null;
}

//export function Ca

export function CalculateBodyAngels(leftInput: NormalizedLandmark[], rightInput: NormalizedLandmark[]): number | null {

  const leftValid = leftInput.length && leftInput.every(x => x.visibility && x.visibility > 0.8)
  const rightValid = rightInput.length && rightInput.every(x => x.visibility && x.visibility > 0.8)

  //console.log({ leftValid, rightValid, leftInput })
  const leftAngel = leftValid ? AngleBetweenJoints(leftInput) : null;
  const rightAngel = rightValid ? AngleBetweenJoints(rightInput) : null;
  document.title = rightValid.toString();
  if (leftValid && !rightValid) {
    return leftAngel;
  }
  if (rightValid && !leftValid) {
    return rightAngel;
  }
  if (leftValid && rightValid) {
    // take the angel with the highest visibility
    return leftInput.reduce((x, z) => (z?.visibility ?? 0) + x, 0) > rightInput.reduce((x, z) => (z?.visibility ?? 0) + x, 0)
      ? leftAngel : rightAngel;
  }
  return null;
}


export function ConvertJointsToAngels(input: NormalizedLandmarkList): BodyAngels {

  //https://google.github.io/mediapipe/solutions/pose.html
  //JointType.ElbowLeft, JointType.WristLeft, JointType.ShoulderLeft
  const elbow = CalculateBodyAngels([input[POSE_LANDMARKS_LEFT.LEFT_ELBOW], input[POSE_LANDMARKS_LEFT.LEFT_WRIST], input[POSE_LANDMARKS_LEFT.LEFT_SHOULDER]], [input[POSE_LANDMARKS_RIGHT.RIGHT_ELBOW], input[POSE_LANDMARKS_RIGHT.RIGHT_WRIST], input[POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER]]);
  const knee = CalculateBodyAngels([input[POSE_LANDMARKS_LEFT.LEFT_KNEE], input[POSE_LANDMARKS_LEFT.LEFT_HIP], input[POSE_LANDMARKS_LEFT.LEFT_ANKLE]], [input[POSE_LANDMARKS_RIGHT.RIGHT_KNEE], input[POSE_LANDMARKS_RIGHT.RIGHT_HIP], input[POSE_LANDMARKS_RIGHT.RIGHT_ANKLE]]);
  const hip = CalculateBodyAngels([input[POSE_LANDMARKS_LEFT.LEFT_HIP], input[POSE_LANDMARKS_LEFT.LEFT_SHOULDER], input[POSE_LANDMARKS_LEFT.LEFT_KNEE]], [input[POSE_LANDMARKS_RIGHT.RIGHT_HIP], input[POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER], input[POSE_LANDMARKS_RIGHT.RIGHT_KNEE]]);

  return {
    elbow: elbow || undefined,
    knee: knee || undefined,
    hip: hip || undefined
  }
}
